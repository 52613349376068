<template>
  <v-card :loading="loading" elevation="2">
    <v-card-title>
      <v-row align="center">
        <v-col cols="2">
          <v-btn
            v-if="number !== ''"
            class="white--text card-base-number-label card-base-number-label-border-radius"
            depressed
            x-small
            color="black"
            :ripple="false"
            min-width="27"
            max-width="40"
            height="19"
          >
            {{ number }}
          </v-btn>
        </v-col>
        <v-col cols="8" class="text-center">
          <h4 class="text-heading-5 main--text">
            {{ title }}
            <template v-if="additionalBadge !== ''">
              <v-chip
                :color="additionalBadgeColor"
                :text-color="additionalBadgeTextColor"
                small
                class="font-weight-bold px-2"
              >
                {{ additionalBadge }}
              </v-chip>
            </template>
          </h4>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row class="py-0">
        <v-col cols="auto" v-if="showDate">
          <h5 class="font-weight-regular main--text lighten-4">{{ date }}</h5>
        </v-col>
        <v-col
          cols="6"
          :offset="!showDate ? '3' : ''"
          class="text-center flex-grow-1"
        >
          <h5 class="font-weight-regular main--text lighten-1">
            {{ contentTitle }}
          </h5>
        </v-col>
        <v-col v-if="helpButton" cols="auto" class="flex-grow-1 text-right">
          <v-btn
            class="white--text help-fab"
            elevation="0"
            color="grey lighten-1"
            :ripple="false"
            x-small
            rounded
            icon
            @click="showModal({ title: modalTitle, component: modalComponent })"
          >
            <v-icon size="14">$question</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <slot name="content-body"></slot>
    </v-card-text>

    <v-card-actions class="px-0">
      <v-col cols="12" class="text-center" v-if="routeDestination !== ''">
        <router-link
          class="font-weight-regular text-uppercase text-subtitle-2 main--text text-decoration-none"
          :to="routeDestination"
        >
          [ {{ routeText }} ]
        </router-link>
      </v-col>

      <slot name="footer"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import dayjs from "@/plugins/dayjs";

export default {
  name: "CardBase",
  props: {
    showDate: {
      type: Boolean,
      default: true,
      required: false
    },
    contentTitle: {
      type: String,
      default: "",
      required: false
    },
    number: {
      type: String,
      default: "",
      required: false
    },
    title: {
      type: String,
      default: "",
      required: true
    },
    routeText: {
      type: String,
      default: "Go to",
      required: false
    },
    routeDestination: {
      type: String,
      default: "",
      required: false
    },
    helpButton: {
      type: Boolean,
      default: true,
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    customDate: {
      type: String,
      default: null,
      required: false
    },
    additionalBadge: {
      type: String,
      default: "",
      required: false
    },
    additionalBadgeColor: {
      type: String,
      default: "",
      required: false
    },
    additionalBadgeTextColor: {
      type: String,
      default: "",
      required: false
    },
    modalTitle: {
      type: String,
      default: "",
      required: false
    },
    modalComponent: {
      type: String,
      default: "",
      required: false
    }
  },
  computed: {
    date() {
      return this.customDate ? this.customDate : dayjs().format("YYYY-MM-DD");
    }
  },
  methods: {
    ...mapMutations(["showModal"])
  }
};
</script>

<style scoped>
.card-base-number-label {
  pointer-events: none;
}
.card-base-number-label-border-radius.v-btn {
  border-radius: 2px !important;
}
</style>
