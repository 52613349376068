var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g({staticClass:"balance-table",attrs:{"items":_vm.items,"headers":_vm.headers,"footer-props":_vm.footers,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"server-items-length":_vm.getBankBalancePagination.records_total,"mobile-breakpoint":"0","dense":"","bind":_vm.$attrs},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.date))]),_c('span',{staticClass:"text-day-name",class:_vm.getDayNameClass(item.day)},[_vm._v(" "+_vm._s(_vm.getDayName(item.date))+" ")])]}},{key:"item.incoming",fn:function(ref){
var value = ref.value;
return [_c('span',{class:!Number.isInteger(value) || 'incoming-cash-cell--text'},[_vm._v(" "+_vm._s(_vm._f("formatThousands")(value))+" ")])]}},{key:"item.outgoing",fn:function(ref){
var value = ref.value;
return [_c('span',{class:!Number.isInteger(value) || 'outgoing-cash-cell--text'},[_vm._v(" "+_vm._s(_vm._f("formatThousands")(value))+" ")])]}},{key:"item.balance",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatThousands")(value))+" ")]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }