<template>
  <v-data-table
    class="balance-table"
    :items="items"
    :headers="headers"
    :footer-props="footers"
    :items-per-page="itemsPerPage"
    :loading="loading"
    :server-items-length="getBankBalancePagination.records_total"
    mobile-breakpoint="0"
    dense
    :bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:item.date="{ item }">
      <span>{{ item.date }}</span>
      <span class="text-day-name" :class="getDayNameClass(item.day)">
        {{ getDayName(item.date) }}
      </span>
    </template>

    <template v-slot:item.incoming="{ value }">
      <span :class="!Number.isInteger(value) || 'incoming-cash-cell--text'">
        {{ value | formatThousands }}
      </span>
    </template>

    <template v-slot:item.outgoing="{ value }">
      <span :class="!Number.isInteger(value) || 'outgoing-cash-cell--text'">
        {{ value | formatThousands }}
      </span>
    </template>

    <template v-slot:item.balance="{ value }">
      {{ value | formatThousands }}
    </template>
  </v-data-table>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";

export default {
  name: "TableBalanceHistory",
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    itemsPerPage: {
      type: Number,
      default: 20,
      required: false
    },
    footers: {
      type: Object,
      default: () => {},
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    ...mapGetters(["getBankBalancePagination"])
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("trading_day"),
          sortable: false,
          value: "date",
          cellClass: "text-cell px-2",
          width: 85,
          align: "center"
        },
        {
          text: this.$t("incoming_cash"),
          sortable: false,
          value: "incoming",
          cellClass: "incoming-cash-cell text-cell",
          width: 92,
          align: "center"
        },
        {
          text: this.$t("outgoing_cash"),
          sortable: false,
          value: "outgoing",
          cellClass: "text-cell",
          width: 92,
          align: "center"
        },
        {
          text: this.$t("balance"),
          sortable: false,
          value: "balance",
          cellClass: "balance-cell text-cell",
          width: 92,
          align: "center"
        }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        console.log("Changes");
      }
    },
    deep: true
  },
  methods: {
    getDayName(day) {
      return dayjs(day).format("dddd");
    },
    getDayNameClass(day) {
      if (day === 0 || day === 6) {
        return "balance-history-table-weekend--text";
      }
    },
    printLog() {
      console.log("Log printed");
    }
  }
};
</script>

<style>
.text-day-name {
  font-size: 9px;
}
.balance-history-table-weekend--text {
  color: #c35b5b;
}
.text-cell {
  font-size: 12px !important;
  font-weight: 400 !important;
  overflow-wrap: anywhere;
}

.incoming-cash-cell {
  background-color: #ebffee !important;
}

.incoming-cash-cell--text {
  color: #00904b;
  font-weight: 700 !important;
}

.outgoing-cash-cell--text {
  color: #6c2525;
  font-weight: 700 !important;
}

.balance-cell {
  background-color: #e8fffe;
}

.v-data-table.balance-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: 2px solid #e6e6e6 !important;
}

.v-data-table.balance-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td {
  border-bottom: 0.5px dashed #c9c9c9 !important;
  border-spacing: 0 !important;
  line-height: 1 !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.v-data-table.balance-table > .v-data-footer {
  border: unset !important;
}
</style>
