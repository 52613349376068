<template>
  <CardBase
    number="3"
    :title="$t('bank_balance_title')"
    :help-button="false"
    :custom-date="getBankBalanceDate"
  >
    <template v-slot:content-body>
      <v-row>
        <v-col cols="auto" class="mr-auto py-0">
          <v-select
            :items="getBankAccounts"
            v-model="bankSelected"
            item-value="id"
            item-text="name"
            return-object
            class="font-weight-bold text-heading-6 bank-list--select pt-0"
            append-icon="$blueDownArrow"
            hide-details
            :placeholder="$t('select_bank')"
          >
          </v-select>
        </v-col>

        <v-col cols="12">
          <v-menu
            ref="dateRangePickerMenu"
            v-model="dateRangePickerMenu"
            :close-on-content-click="false"
            :return-value.sync="dateRange"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row align="center">
                <v-col cols="4">
                  <v-text-field
                    v-model="dateRange[0]"
                    readonly
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    height="11"
                    class="text-heading-1 date-range--input"
                  >
                  </v-text-field>
                </v-col>
                ~
                <v-col cols="4">
                  <v-text-field
                    v-model="dateRange[1]"
                    readonly
                    outlined
                    dense
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    height="11"
                    class="text-heading-1 date-range--input"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="2">
                  <v-btn
                    color="primary"
                    small
                    depressed
                    height="20"
                    min-height="20"
                    class="text-heading-1"
                    @click="getDataFromApi"
                    :loading="loading"
                  >
                    {{ $t("apply") }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <v-date-picker
              color="primary"
              range
              v-model="dateRange"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateRangePickerMenu = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dateRangePickerMenu.save(dateRange)"
              >
                {{ $t("ok") }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col v-if="bankSelected !== null" cols="12" class="px-8">
          <v-card class="current-balance-card rounded-0">
            <v-card-text>
              <v-row
                align="center"
                class="font-weight-bold main--text py-4 text-center"
              >
                <v-col cols="auto">
                  <div class="text-heading-3">
                    {{ $t("current_balance") }}
                  </div>
                </v-col>

                <v-col cols="auto">
                  <div class="text-heading-8">
                    {{ bankSelected.balance | toCurrency }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="balances.length !== 0"
          cols="12"
          class="d-inline main--text text-heading-1"
        >
          <span class="font-weight-bold">
            {{
              $t("bank_balance_item_count", {
                first_item: pagination.records_from,
                last_item: pagination.records_to,
                total: pagination.records_total
              })
            }}
          </span>
          {{ $t("bank_balance_item_count_message") }}
        </v-col>
      </v-row>
    </template>

    <template v-if="balances.length !== 0" v-slot:footer>
      <TableBalanceHistory
        :items="balances"
        :items-per-page="itemsPerPage"
        :footers="footers"
        :loading="tableLoading"
        @update:options="updateTable"
      />
    </template>
  </CardBase>
</template>

<script>
import CardBase from "@/components/ui/CardBase";
import TableBalanceHistory from "@/components/ui/TableBalanceHistory";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";

export default {
  name: "BankBalanceCard",
  components: { TableBalanceHistory, CardBase },
  computed: {
    ...mapGetters([
      "getBankAccounts",
      "getBankBalance",
      "getBankBalanceDate",
      "getBankBalancePagination"
    ]),

    pagination() {
      return this.getBankBalancePagination;
    },

    footers() {
      return {
        itemsPerPageText: this.$t("items_per_page"),
        itemsPerPageOptions: [this.itemsPerPage],
        pagination: {
          page: 1,
          itemsPerPage: this.itemsPerPage,
          pageStart: this.getBankBalancePagination.records_from - 1,
          pageStop: this.getBankBalancePagination.records_to,
          pageCount: this.getBankBalancePagination.total_pages,
          itemsLength: this.getBankBalancePagination.records_total
        },
        showCurrentPage: true
      };
    }
  },
  created() {
    this.bankSelected = this.getBankAccounts[0];
    this.getDataFromApi();
  },
  data() {
    return {
      loading: false,
      tableLoading: false,
      dateRangePickerMenu: false,
      dateRange: [
        dayjs()
          .subtract(45, "days")
          .format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD")
      ],
      bankSelected: null,
      balances: [],
      itemsPerPage: 25,
      options: {},
      initialLoad: true
    };
  },
  methods: {
    updateTable(e) {
      if (this.initialLoad) return;
      this.tableLoading = true;
      this.getDataFromApi(e.page);
    },
    getDataFromApi(page) {
      this.loading = true;
      this.$store
        .dispatch("BANK_BALANCE_GET", {
          id: this.bankSelected.id,
          query: {
            params: {
              date_from: this.dateRange[0],
              date_to: this.dateRange[1],
              paginate: this.itemsPerPage,
              page: page
            }
          }
        })
        .then(() => {
          let balances = [];
          for (const item of this.getBankBalance) {
            balances.push({
              date: item.date,
              day: dayjs(item.date).day(),
              incoming: item.amount.incoming,
              outgoing: item.amount.outgoing,
              balance: item.balance
            });
          }

          this.balances = balances;

          this.initialLoad = false;
        })
        .finally(() => {
          this.loading = false;
          this.tableLoading = false;
        });
    }
  }
};
</script>

<style scoped>
.current-balance-card {
  background-color: #fffceb !important;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.15) !important;
}

.bank-list--select >>> svg.v-icon__component.theme--light {
  height: 9px !important;
  width: 9px !important;
}

.date-range--input >>> .v-input__slot {
  min-height: 20px !important;
}
</style>

<style>
.bank-list--select.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none !important;
}
.bank-list--select.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none !important;
}
</style>
