<template>
  <v-container v-if="!loading" fluid class="px-0">
    <BankBalanceCard />
  </v-container>
</template>

<script>
import BankBalanceCard from "@/components/layout/bank/BankBalanceCard";
export default {
  name: "BankBalance",
  components: { BankBalanceCard },
  created() {
    this.loading = true;
    this.$store
      .dispatch("BANK_ACCOUNTS_GET")
      .finally(() => (this.loading = false));
  },
  data() {
    return {
      loading: false
    };
  }
};
</script>

<style scoped></style>
